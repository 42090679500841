import React from "react"

import Profile from "../../components/idealline/Profile"
import FirebaseProvider from "../../components/idealline/Firebase"
import { BrowserRouter } from "react-router-dom"
import { ClientOnly } from "../../components/common"

const ProfilePages = (props) => {
    return (
            <ClientOnly>
                <BrowserRouter>
                    <FirebaseProvider>
                        <Profile />
                    </FirebaseProvider>
                </BrowserRouter>
            </ClientOnly>
    )
}

export default ProfilePages

